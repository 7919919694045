import React from "react"
import Photo1 from "../images/photos-section/1"
import Photo3 from "../images/photos-section/3"
import Photo7 from "../images/photos-section/7"
import Photo8 from "../images/photos-section/8"
import Photo9 from "../images/photos-section/9"
import Photo10 from "../images/photos-section/10"
import Photo11 from "../images/photos-section/11"
import Photo12 from "../images/photos-section/12"
import Photo13 from "../images/photos-section/13"
import Photo14 from "../images/photos-section/14"
import Photo15 from "../images/photos-section/15"
import Photo16 from "../images/photos-section/16"
import Photo17 from "../images/photos-section/17"
import Photo18 from "../images/photos-section/18"
import styled from "styled-components"
import { TitleSection } from "../TitleSection"

const PhotoContainer = styled.div`
  flex: 1;
  min-width: 300px;
  padding: 5px;
  .gatsby-image-wrapper {
    height: 100%;
  }
`

const IndexPage = () => (
  <div style={{ background: "black", overflowX: "hidden" }}>
    <TitleSection title="Photos" type="photo" />

    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <PhotoContainer data-sal="slide-right" data-sal-duration="1000">
        <Photo3 />
      </PhotoContainer>
      <PhotoContainer data-sal="slide-left" data-sal-duration="1000">
        <Photo9 />
      </PhotoContainer>
    </div>
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <PhotoContainer data-sal="slide-right" data-sal-duration="1000">
        <Photo11 />
      </PhotoContainer>
      <PhotoContainer data-sal="slide-left" data-sal-duration="1000">
        <Photo12 />
      </PhotoContainer>
    </div>
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <PhotoContainer data-sal="slide-right" data-sal-duration="1000">
        <Photo8 />
      </PhotoContainer>
      <PhotoContainer data-sal="slide-left" data-sal-duration="1000">
        <Photo14 />
      </PhotoContainer>
    </div>
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <PhotoContainer data-sal="slide-right" data-sal-duration="1000">
        <Photo15 />
      </PhotoContainer>
      <PhotoContainer data-sal="slide-left" data-sal-duration="1000">
        <Photo16 />
      </PhotoContainer>
    </div>
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <PhotoContainer data-sal="slide-right" data-sal-duration="1000">
        <Photo17 />
      </PhotoContainer>
      <PhotoContainer data-sal="slide-left" data-sal-duration="1000">
        <Photo18 />
      </PhotoContainer>
    </div>
  </div>
)

export default IndexPage
