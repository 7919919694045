import React from "react"
import styled from "styled-components"
import { VideoWrapper } from "."
import Item1Icon from "../../images/drone/1.svg"
import Item2Icon from "../../images/drone/2.svg"
import Item3Icon from "../../images/drone/3.svg"
import { VideoPlayer } from "../VideoPlayer"
import { AnimatedDrone } from "./AimatedDrone"
const Title = styled.h2`
  text-align: center;
  font-size: 40px;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`

const ItemsWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
  flex-wrap: wrap;
  padding-left: 40px;
  @media (max-width: 768px) {
    padding-left: 0px;
  }
`

const ItemContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-right: 40px;
  margin-bottom: 40px;
  background: #222530;
  padding: 20px;
  border-radius: 15px;
  margin-top: 15px;
  box-shadow: "0px 0px 11px 0px #222530";
  min-width: 360px;
  @media (max-width: 768px) {
    padding-top: 10px;
    margin: 10px;
  }
`

const SubTitle = styled.p`
  font-size: 30px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 80px;
  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 40px;
  }
`

const Item = ({ title, img, description, duration }) => (
  <ItemContent data-sal="slide-up" data-sal-duration={duration}>
    {img}
    <p style={{ fontWeight: 700, textAlign: "center", color: "#edc681" }}>
      {title}
    </p>
    <p style={{ textAlign: "center" }}>{description}</p>
  </ItemContent>
)

const DroneSection = () => {
  const [ready, setReady] = React.useState(false)
  return (
    <div
      style={{
        paddingTop: 80,
        paddingBottom: 80,
        background: "black",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          items: "center",
        }}
      >
        <AnimatedDrone />
      </div>
      <Title>
        Realisations de séquences cinématographiques vue du ciel (drone)
      </Title>
      <ItemsWrapper>
        <Item
          className="item"
          img={<img src={Item1Icon} alt="repairage" width={60} />}
          duration={1000}
          title="1. REPAIRAGE"
          description="Nous vous accompagnons dès le départ de votre projet pour cibler et définir vos envies et besoins. Nous vous proposerons les solutions les plus adaptées."
        />
        <Item
          className="item"
          duration={1500}
          title="2. TOURNAGE"
          description="De l’image aérienne par Drone, à l’image au sol, en passant par le son, nous mettons les dernières technologies et notre expérience à votre service pour un résultat de qualité."
          img={<img src={Item2Icon} alt="tournage" width={60} />}
        />
        <Item
          className="item"
          duration={2000}
          title="3. POST-PRODUCTION"
          description="Montage, mixage, effet spéciaux, motion design, musique… Nous finalisons votre projet grâce à nos compétences dans le domaine, afin de vous satisfaire pleinement."
          img={<img src={Item3Icon} alt="post-production" width={60} />}
        />
      </ItemsWrapper>
      <SubTitle>Nos derniers réalisation :</SubTitle>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          background: "black",
        }}
      >
        <VideoWrapper data-sal="slide-right" data-sal-duration="1000">
          <VideoPlayer url="https://fb.watch/k_IzkT5qmA/" />
        </VideoWrapper>
        <VideoWrapper data-sal="slide-left" data-sal-duration="1000">
          <VideoPlayer url="https://fb.watch/k_IDVfSvN5/" />
        </VideoWrapper>
      </div>
    </div>
  )
}
export { DroneSection }
