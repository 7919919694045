import { useEffect } from "react"
import PropTypes from "prop-types"
import React from "react"

const OnScroll = ({ topOffset, onScrollDownOffset, onScrollUpOffset }) => {
  const handleScroll = () => {
    if (window.pageYOffset >= topOffset) {
      onScrollDownOffset()
    } else {
      onScrollUpOffset()
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll, { passive: true })
    }
  }, [])
  return <div />
}

OnScroll.defaultProps = {
  topOffset: 30,
}

OnScroll.propTypes = {
  topOffset: PropTypes.number,
  onScrollDownOffset: PropTypes.func,
  onScrollUpOffset: PropTypes.func,
}
export default OnScroll
