import React, { useState } from "react"
import FacbookIcon from "../../images/social-media/facbook-athman.png"
import FacbookIconHover from "../../images/social-media/facbook-athman-hover.png"
import TwitterIcon from "../../images/social-media/twitter-athman.png"
import TwitterIconHover from "../../images/social-media/twitter-athman-hover.png"
import InstagramIcon from "../../images/social-media/instagram-athman.png"
import InstagramIconHover from "../../images/social-media/instagram-athman-hover.png"
import TiktokIcon from "../../images/social-media/tiktok-athman.png"
import TiktokIconHover from "../../images/social-media/tiktok-athman-hover.png"
import styled from "styled-components"
import Logo from "../../images/logo.png"
import { Fragment } from "react"
import OnScroll from "../OnScroll"
import breakpoint from "styled-components-breakpoint"

const SocialMediaDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 40px;
  padding-right: 40px;
  background: "black";
  ${breakpoint("desktop")`
  display: flex;
`}
  ${breakpoint("mobile")`
    display: none;
  `}
`
const SocialMediaIcon = styled.img`
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
  margin-bottom: 0;
  ${breakpoint("desktop")`
  width: 40px;
`}
  ${breakpoint("mobile")`
   width: 30px;
`}
`

const FixedNav = styled.div`
  display: flex;
  justify-items: center;
  box-shadow: 0 6px 6px -6px #e9ebee;
  background: black;
  position: fixed;
  top: 0;
  height: 69px;
  width: 100%;
  overflow: hidden;
  z-index: 9;
  ${breakpoint("desktop")`
  padding: 5px 40px;
`}
  ${breakpoint("mobile")`
  padding: 5px 10px;
 `}
`
const LogoImage = styled.img`
  margin-bottom: 0px;
  height: 40px;
  ${breakpoint("desktop")`
  width: 140px
`}
  ${breakpoint("mobile")`
  width: 120px
 `}
`

const SocialMedia = () => {
  const [facbookHover, setFacbookHover] = useState(false)
  const [twitterHover, setTwitterHover] = useState(false)
  const [instagramHover, setInstagramHover] = useState(false)
  const [tiktokHover, setTiktokHover] = useState(false)

  const smoothTransition = (stuff) =>
    setTimeout(() => {
      stuff()
    }, 200)

  return (
    <Fragment>
      <a href="https://www.facebook.com/outman.djeff" target="_blank">
        <SocialMediaIcon
          src={facbookHover ? FacbookIconHover : FacbookIcon}
          onMouseEnter={() => smoothTransition(() => setFacbookHover(true))}
          onMouseLeave={() => smoothTransition(() => setFacbookHover(false))}
        />
      </a>
      <a
        target="_blank"
        href="https://mobile.twitter.com/OthmaneDjafri?fbclid=IwAR1tBw0NTkNf-8Lb9fGG6jbErAateV_AaO9LnDcDYfDFTYItEFfFAH_T57I"
      >
        <SocialMediaIcon
          src={twitterHover ? TwitterIconHover : TwitterIcon}
          onMouseEnter={() => smoothTransition(() => setTwitterHover(true))}
          onMouseLeave={() => smoothTransition(() => setTwitterHover(false))}
        />
      </a>
      <a href="https://www.instagram.com/othmane_djafri/?hl=fr" target="_blank">
        <SocialMediaIcon
          src={instagramHover ? InstagramIconHover : InstagramIcon}
          onMouseEnter={() => smoothTransition(() => setInstagramHover(true))}
          onMouseLeave={() => smoothTransition(() => setInstagramHover(false))}
        />
      </a>
      <a target="_blank" href="https://www.tiktok.com/@othmane_djafri">
        <SocialMediaIcon
          src={tiktokHover ? TiktokIconHover : TiktokIcon}
          onMouseEnter={() => smoothTransition(() => setTiktokHover(true))}
          onMouseLeave={() => smoothTransition(() => setTiktokHover(false))}
        />
      </a>
    </Fragment>
  )
}

const NavBar = () => {
  const [displayNav, setDisplayNav] = useState(false)

  return (
    <div>
      <SocialMediaDiv>
        <SocialMedia />
      </SocialMediaDiv>
      <OnScroll
        topOffset={40}
        onScrollDownOffset={() => setDisplayNav(true)}
        onScrollUpOffset={() => setDisplayNav(false)}
      />
      {displayNav && (
        <FixedNav>
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              paddingBottom: 5,
              paddingLeft: 8,
            }}
          >
            <LogoImage src={Logo} />
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "flex-end",
              alignItems: "center",
              padding: "11px 0px",
            }}
          >
            <SocialMedia />
          </div>
        </FixedNav>
      )}
    </div>
  )
}

export default NavBar
