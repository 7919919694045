import React from "react"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { TitleSection } from "../TitleSection"
import { VideoPlayer } from "../VideoPlayer"

const fbVideos = [
  "https://www.facebook.com/outman.djeff/videos/393318160264202/",
  "https://fb.watch/k-f041sH2a/",
  "https://www.facebook.com/watch/?v=912225650190905",
  "https://www.facebook.com/watch/?v=1316226472483808",
  "https://www.facebook.com/watch/?v=535592871127195",
  "https://www.facebook.com/watch/?v=1103703563787219",
]
const tiktokVideos = [
  "https://www.tiktok.com/embed/7173653971616255238",
  "https://www.tiktok.com/embed/7098221809354460421",
  "https://www.tiktok.com/embed/7178999964159233285",
  "https://www.tiktok.com/embed/7098221809354460421",
  "https://www.tiktok.com/embed/7185231970975108357",
  "https://www.tiktok.com/embed/7163418224015592709",
  // "https://www.tiktok.com/@othmane_djafri/video/7173653971616255238",
  // "https://www.tiktok.com/@othmane_djafri/video/7098221809354460421",
  // "https://www.tiktok.com/@othmane_djafri/video/7178999964159233285",
  // "https://www.tiktok.com/@othmane_djafri/video/7098221809354460421",
  // "https://www.tiktok.com/@othmane_djafri/video/7185231970975108357",
]

export const VideoWrapper = styled.div`
  padding: 5px;
  flex: 1;
  ${breakpoint("desktop")`
   min-width: 700px;
 `}
  ${breakpoint("mobile")`
   min-width: "310px";
 `}
background: black;
`

const TiktokItemWrapper = styled.div`
  margin: 10px;
  border: 1px solid white;
  transition: 0.3s;
  &:hover {
    border: 1px solid #edc681;
  }
`

const TiktokWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    justify-content: center;
  }
`
const Videos = () => {
  const [state, setstate] = React.useState()
  return (
    <div style={{ background: "black" }}>
      <TitleSection title="Videos" type="video" />

      <div style={{ display: "flex", flexWrap: "wrap", background: "black" }}>
        {fbVideos.map((url, index) => (
          <VideoWrapper key={index} data-sal="zoom-in" data-sal-duration="1000">
            <VideoPlayer url={url} />
          </VideoWrapper>
        ))}
      </div>

      <TitleSection title="Tiktok videos" type="tiktok" />

      <TiktokWrapper>
        {tiktokVideos.map((url, index) => (
          <TiktokItemWrapper
            key={index}
            id="tiktok-wrapper"
            data-sal="zoom-in"
            data-sal-duration="1000"
          >
            <div
              style={{
                left: 0,
                width: "100%",
                height: 500,
              }}
            >
              <iframe
                src={url}
                style={{
                  height: "100%",
                  width: "100%",
                  border: 0,
                }}
                allowFullScreen
                scrolling="no"
                allow="encrypted-media;"
              ></iframe>
            </div>
          </TiktokItemWrapper>
        ))}
      </TiktokWrapper>
    </div>
  )
}

export default Videos
