import React, { useRef } from "react"
import ReactPlayer from "react-player"

const VideoPlayer = ({ url }) => {
  const ref = useRef(null)
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(false)

  console.log("________ ref?.current?.textContent ", ref?.current)
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyItems: "center",
        justifyContent: "center",
        position: "relative",
        minHeight: "400px",
        minWidth: "250px",
      }}
    >
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flex: 1,
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5px",
          }}
        >
          <div className="card is-loading">
            <div className="image"></div>
            <div className="content">
              <h2></h2>
              <p></p>
            </div>
          </div>
        </div>
      )}
      <ReactPlayer
        ref={ref}
        url={url}
        width="100%"
        height="100%"
        onReady={() => setLoading(false)}
      />
    </div>
  )
}
export { VideoPlayer }
