import React from "react"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import AthmanName from "../../images/athman-name.png"
import AthmanPhoto from "../images/athman-photo"

const ImageContainerRoot = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const AthmanNameContainer = styled.div`
  &:hover {
  }
  ${breakpoint("tablet")`
  min-width: 450px;
`}
  ${breakpoint("mobile")`
  min-width: 220px;
  margin-top: 30px
`}
`

const ImageContainer = styled.div`
  ${breakpoint("mobile")`
 padding-top: 30px
`}
`
const Container = styled.div`
  display: "flex";
  flex-direction: column;
  background: black;
  height: 100%;
`

const DescriptionTExt = styled.h2`
  font-size: 30px;
  font-weight: 700;

  ${breakpoint("tablet")`
    margin-top: 104px
  `}
  ${breakpoint("mobile")`
    text-align: center;
  `}
`

const Home = () => {
  const [state, setstate] = React.useState()
  return (
    <Container>
      <ImageContainer>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ flex: 1, minWidth: "320px" }}>
            <AthmanPhoto />
          </div>
          <ImageContainerRoot>
            <AthmanNameContainer className="flicker-in-1">
              <img src={AthmanName} style={{ padding: 15 }} />
            </AthmanNameContainer>
            <div>
              <DescriptionTExt>
                Filmmaker and Vlogger (Algerian tourist)
              </DescriptionTExt>
            </div>
          </ImageContainerRoot>
        </div>
      </ImageContainer>
    </Container>
  )
}

export default Home
