import React from "react"
import PhotoIcon from "../images/photo.png"
import VideoIcon from "../images/video.png"
import TiktokIcon from "../images/tiktok.png"
import DroneIcon from "../images/drone.png"
import styled from "styled-components"

const Wrapper = styled.div`
  padding: 30px;
  padding-left: 5px;
  display: flex;
  @media (max-width: 768px) {
    padding: 20px;
  }
`

const TitleSection = ({ title, type }) => {
  const imageByType = {
    tiktok: () => (
      <img src={TiktokIcon} alt="tiktok icon" width={40} height={40} />
    ),
    video: () => (
      <img src={VideoIcon} alt="video icon" width={40} height={40} />
    ),
    drone: () => (
      <img src={DroneIcon} alt="drone icon" width={40} height={40} />
    ),
    photo: () => (
      <img src={PhotoIcon} alt="photo icon" width={40} height={40} />
    ),
  }
  return (
    <Wrapper>
      {imageByType[type]()}
      <h2
        style={{
          fontSize: 40,
          marginLeft: 10,
        }}
      >
        {title}
      </h2>
    </Wrapper>
  )
}

export { TitleSection }
