/* eslint-disable no-constant-condition */
import emailjs from "emailjs-com"
import { useFormik } from "formik"
import React, { useState } from "react"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import * as yup from "yup"
import ContactText from "../../images/contact-text.png"

const Form = styled.form`
  #mydiv {
    opacity: 1;
    transition: opacity 3s;
  }

  padding: 40px;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  border-bottom: 1px solid #fff;
`
const Div = styled.div`
  width: "100%";
  min-width: 300px;
  ${breakpoint("tablet")`
  padding-right: 20px;
`}
  ${breakpoint("mobile")`
  padding-right: 0px;
  `}
`

const validationSchema = yup.object({
  name: yup.string().required("Le nom est obligatoir"),
  email: yup
    .string()
    .email("L'email n'est pas valid")
    .required("L'email est obligatoir"),
  phone: yup
    .string()
    .matches(/^\(?([0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{5})$/, {
      message: "Veuillez introduire un numero valid.",
      excludeEmptyString: false,
    }),
  message: yup.string("").required("Le message est obligatoir"),
})

const Contact = () => {
  const [msgSent, setMsgSent] = React.useState(false)
  const [sending, setSending] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      phone: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => sendEmail(values),
  })

  const sendEmail = async (values) => {
    try {
      setSending(true)
      const sendingResult = await emailjs.send(
        process.env.GATSBY_EMAILJS_SERVICE_ID,
        process.env.GATSBY_EMAILJS_TEMPLATE_ID,
        {
          from_name: formik.values.name,
          from_email: formik.values.email,
          phone: formik.values.phone,
          message: formik.values.message,
        },
        process.env.GATSBY_EMAILJS_USER_ID
      )
      console.log("--------------- sending email sendingResult ", sendingResult)
      setSending(false)
      setMsgSent(true)
      return sendingResult
    } catch (err) {
      console.log("--------------- sending email err ", err)
    }
  }

  const ErrorText = ({ field }) => (
    <p
      style={{
        color: "#b11515",
        fontWeight: "bold",
        marginTop: "5px",
        fontSize: "14px",
        marginBottom: 0,
      }}
    >
      {Boolean(formik.errors) && formik.errors[field]}
    </p>
  )
  return (
    <Form id="#myform" onSubmit={formik.handleSubmit}>
      <div className="container" data-sal="slide-up" data-sal-duration="1000">
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 60 }}
        >
          <img src={ContactText} />
        </div>
        {msgSent ? (
          <div>
            <p
              style={{ color: "white", opacity: 1, transition: "opacity 3s" }}
              id="mydiv"
            >
              Nous avons bien reçu votre message. nous vous répondrons au plus
              vite. merci de votre patience
            </p>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div className="styled-input" style={{ width: "100%" }}>
              <input
                name="name"
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              <label>Name</label>
              <ErrorText field="name" />
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div style={{ flex: 1 }}>
                <Div className="styled-input">
                  <input
                    type="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                  />
                  <label>Email</label>
                  <ErrorText field="email" />
                </Div>
              </div>
              <div style={{ flex: 1 }}>
                <div
                  className="styled-input"
                  style={{ width: "100%", minWidth: 300 }}
                >
                  <input
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                  />
                  <label>Numero de telephone</label>
                  <ErrorText field="phone" />
                </div>
              </div>
            </div>
            <div>
              <div className="styled-input" style={{ width: "100%" }}>
                <textarea
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                ></textarea>
                <label>Message</label>
                <ErrorText field="message" />
              </div>
            </div>
            <div style={{ display: "flex", flex: 1, justifyContent: "end" }}>
              {sending ? (
                <div className="loader"></div>
              ) : (
                <button type="submit" className="btn-lrg submit-btn">
                  <span>Envoyer le Message</span>
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </Form>
  )
}
export default Contact
