import React from "react"
import styled from "styled-components"

const CustomLink = styled.a`
  display: inline;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: #edc681;

  font-family: bold;
`
const FlexCenter = styled.div`
  display: "flex";
  align-items: "center";
  justify-content: "center";
`

const Footer = () => {
  const [state, setstate] = React.useState()
  return (
    <footer
      style={{
        background: "black",
        bottom: "0px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "66px",
      }}
    >
      <div
        style={{ background: "black", display: "flex", alignItems: "center" }}
      >
        <FlexCenter>
          <p
            style={{
              color: "white",
              marginTop: 5,
              textAlign: "center",
              fontSize: "14px",
              marginBottom: 0,
            }}
          >
            © {new Date().getFullYear()}, by{" "}
            <CustomLink href="https://amine.top" target="_blanc">
              Amine.Top
            </CustomLink>
          </p>
        </FlexCenter>
      </div>
    </footer>
  )
}
export default Footer
