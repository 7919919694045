import React from "react"
import Layout from "../components/Layout"
import Home from "../components/Home"
import Photos from "../components/Photos"
import NavBar from "../components/navBar"
import Videos from "../components/videos"
import Footer from "../components/Footer"
import Contact from "../components/Contact"
import SEO from "../components/seo"

import "../global.css"
import { DroneSection } from "../components/videos/DroneSection"
const IndexPage = () => (
  <Layout>
    <div style={{ height: "100vh", background: "black" }}>
      <SEO />

      <NavBar />
      <Home />
    </div>
    <DroneSection />

    <Photos />
    <Videos />
    <Contact />
    <Footer />
  </Layout>
)

export default IndexPage
